import React from 'react';

function Portfolio() {
  return (
    <section id="portfolio">
      <h2>Portfolio</h2>
      {/* Portfolio items go here */}
    </section>
  );
}

export default Portfolio;
